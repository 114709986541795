<template>
  <section class="pt-50 pb-5">
    <div
      class="container pt-5 d-flex align-items-center justify-content-center m-auto login"
      style="min-height: 65vh"
    >
      <div class="card m-auto col-md-3 p-5">
        <div class="text-center intro">
          <img src="@/assets/img/logo.png" width="160" class="m-auto" />
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div class="mt-4 text-center">
              <h4>Reset Password</h4>
              <validation-provider
                v-slot="{ errors, pristine }"
                name="email"
                rules="required"
              >
                <div class="mt-3 inputbox">
                  <input
                    type="text"
                    class="form-control"
                    name="email"
                    placeholder="Email"
                    v-model="form.email"
                    :class="{
                      'is-invalid': errors[0],
                      'is-valid': !errors[0] && !pristine,
                    }"
                  />
                  <i class="fa fa-user"></i>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </div>
              </validation-provider>
            </div>

            <div class="mybtn m-auto mt-3 d-flex justify-content-between">
              <button
                v-if="!loading"
                type="submit"
                class="btn btn-primary rounded-0 px-5 mb-2 mb-lg-0 me-3 addnewbtn allbtn"
              >
                <span class="d-flex align-items-center"
                  ><span class="small">Reset</span></span
                >
              </button>
              <button v-else class="btn btn-primary" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
              <button
                type="button"
                @click="$router.push('/login')"
                class="btn btn-danger rounded-0 px-5 mb-2 mb-lg-0 me-3 allbtn"
              >
                <span class="d-flex align-items-center"
                  ><span class="small">Cancel</span></span
                >
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PasswordResetRequest",
  data() {
    return {
      form: {
        email: "",
      },
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      let data = new FormData();
      data.append("email", this.form.email);
      await this.$repository.password
        .request(data)
        .then(() => {
          this.loading = false;
          this.$toast.success(
            "Reset Password link has been send to your mail. Please check your mail"
          );
        })
        .catch((err) => {
          console.log(err.response);
          this.loading = false;
          if (err.response.data.email) {
            this.$toast.error(err.response.data.email[0]);
          } else {
            this.$toast.error("Something went Wrong");
          }
        });
    },
  },
};
</script>

<style scoped></style>
